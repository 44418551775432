import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { MARKS, BLOCKS, INLINES } from "@contentful/rich-text-types";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import OkrExampleCard from "../components/OkrExampleCard";

export const query = graphql`
	query ($slug: String!) {
		allContentfulTag(
			filter: {
				okr_example: {
					elemMatch: { tag: { elemMatch: { slug: { eq: $slug } } } }
				}
			}
		) {
			edges {
				node {
					id
					slug
					title
				}
			}
		}
		contentfulTag(slug: { eq: $slug }) {
			body {
				raw
			}
			description
			createdAt(formatString: "DD MM YYY")
			image {
				gatsbyImageData
				url
			}
			metaDescription
			metaTitle
			title
			slug
			updatedAt
		}
		allContentfulOkrExample(
			filter: { tag: { elemMatch: { slug: { eq: $slug } } } }
			sort: { fields: updatedAt, order: DESC }
		) {
			edges {
				node {
					objective
					keyResults
					id
					slug
					title
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
				title
			}
		}
	}
`;

const OkrTag = (props) => {
	const relatedTags = props.data.allContentfulTag;
	const examples = props.data.allContentfulOkrExample;
	const post = props.data.contentfulTag;
	const description = post.metaDescription
		? post.metaDescription
		: post.description;
	const site = props.data.site;

	const options = {
		renderNode: {
			"embedded-asset-block": (node) => {
				return (
					<>
						<GatsbyImage
							image={node.data.target.gatsbyImageData}
							alt={node.data.target.title}
						/>
					</>
				);
			},
			[BLOCKS.LIST_ITEM]: (node, children) => {
				return (
					<li className="list-disc ml-4 mt-1.5 text-thin text-slate-800">
						{children}
					</li>
				);
			},
			[BLOCKS.HEADING_2]: (node, children) => {
				return (
					<h2 className="mt-8 mb-4 text-4xl font-bold text-slate-800">
						{children}
					</h2>
				);
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				return (
					<h3 className="mb-4 mt-6 text-3xl font-bold text-slate-900">
						{children}
					</h3>
				);
			},
			[BLOCKS.PARAGRAPH]: (node, children) => {
				if (
					node.content.length === 1 &&
					node.content[0].marks.find((x) => x.type === "code")
				) {
					return <div>{children}</div>;
				}
				return <p className="">{children}</p>;
			},
			[BLOCKS.CODE]: (text) => {
				return <SyntaxHighlighter style={a11yDark}>{text}</SyntaxHighlighter>;
			},
			[INLINES.HYPERLINK]: (node) => {
				return (
					<a
						className="underline text-slate-600"
						href={node.data.uri}
						target={`${
							node.data.uri.startsWith(site.siteMetadata.siteUrl)
								? "_self"
								: "_blank"
						}`}
						rel={`${
							node.data.uri.startsWith(site.siteMetadata.siteUrl)
								? ""
								: "noopener noreferrer"
						}`}>
						{node.content[0].value}
					</a>
				);
			},
		},
		renderMark: {
			[MARKS.CODE]: (text) => {
				return <SyntaxHighlighter style={a11yDark}>{text}</SyntaxHighlighter>;
			},
		},
	};

	const questions = post.faq?.map((edge) => {
		return {
			"@type": "Question",
			name: `${edge.question}`,
			acceptedAnswer: {
				"@type": "Answer",
				text: `${edge.answer.answer}`,
			},
		};
	});

	const faq = post.faq
		? {
				"@context": "https://schema.org",
				"@type": "FAQPage",
				mainEntity: [questions],
		  }
		: null;

	const schema = [
		{
			"@context": "https://schema.org",
			"@type": "BlogPosting",
			mainEntityOfPage: {
				"@type": "WebPage",
				"@id": `/okr-examples/${post.slug}`,
			},
			headline: `${post.metaTitle}`,
			description: `${description}`,
			image: `${post.image.url}`,
			datePublished: `${post.updatedAt}`,
			dateModified: `${post.createdAt}`,
		},
		{
			"@context": "https://schema.org",
			"@type": "BreadcrumbList",
			itemListElement: [
				{
					"@type": "ListItem",
					position: 1,
					item: {
						"@id": `${site.siteMetadata.siteUrl}/okr-examples/`,
						name: `OKR Examples`,
					},
				},
				{
					"@type": "ListItem",
					position: 2,
					item: {
						"@id": `${site.siteMetadata.siteUrl}/okr-examples/${post.slug}`,
						name: `${post.metaTitle}`,
					},
				},
			],
		},
	];

	return (
		<Layout>
			<Head
				title={`${post.metaTitle} | ${props.data.site.siteMetadata.title}`}
				description={description}
				image={post.image.url}
				url={`${props.data.site.siteMetadata.siteUrl}/${post.slug}`}
				schemaMarkup={schema}
				faq={faq}
			/>

			<main className="mx-auto max-w-4xl">
				<div className="mt-8 sm:mt-12 md:mt-16">
					<h1 className="text-4xl sm:text-5xl md:text-6xl flex flex-col-reverse font-bold tracking-tight text-slate-900">
						{post.title}
						<span className="text-lg font-bold tracking-normal">
							{" "}
							OKR Examples
						</span>
					</h1>
					<p className="mt-3 text-base text-slate-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl">
						{post.description}
					</p>
				</div>
				<div className="mt-4 md:mt-8">
					{examples.edges.length > 0 && (
						<>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								{examples.edges.map((edge) => {
									return <OkrExampleCard key={edge.node.id} edge={edge} />;
								})}
							</div>
						</>
					)}

					{relatedTags.length > 0 && (
						<div className="my-10">
							<h2 class="text-4xl font-bold text-slate-900">
								Browse other OKR examples:
							</h2>
							<div className="flex flex-wrap flex-row mt-4">
								{relatedTags.map((edge) => {
									return (
										<Link
											key={edge.node.id}
											to={`/okr-examples/${edge.node.slug}`}
											className="m-1 rounded-full hover:cursor-pointer  text-slate-100 hover:bg-indigo-500 bg-indigo-600 active:bg-indigo-700 px-3 py-1 text-sm font-medium">
											{edge.node.title}
										</Link>
									);
								})}
							</div>
						</div>
					)}

					<article className="mt-10 sm:mt-16 prose-a:text-indigo-600 prose-p:text-lg prose-p:my-6 prose-blockquote:my-4 prose-blockquote:bg-indigo-500 prose-blockquote:text-white prose-blockquote:mb-4 prose-blockquote:rounded prose-blockquote:px-4 prose-blockquote:py-1 ">
						<p className="">{post.excerpt}</p>
						{renderRichText(post.body, options)}
					</article>
				</div>
			</main>
		</Layout>
	);
};

export default OkrTag;
