import React from "react";
import { Link } from "gatsby";

const OkrExampleCard = ({ edge }) => {
	return (
		<div className="rounded-md bg-slate-100 border p-4">
			<div className="flex flex-col">
				<h2 className="text-3xl font-semibold mb-4 mt-1 break-words">
					{edge.node.objective}
				</h2>
				<p className="font-semibold">Key results:</p>
				<ul className="list-disc pl-4 my-2">
					{edge.node.keyResults.map((edge) => {
						return (
							<li key={edge} className="">
								{edge}
							</li>
						);
					})}
				</ul>
				<div className="align-bottom flex justify-between items-center self-end w-full mt-2">
					<Link
						className="underline font-semibold text-indigo-600 hover:text-indigo-400 active:text-indigo-600"
						to={`/okr-example/${edge.node.slug}`}>
						Learn more →
					</Link>
				</div>
			</div>
		</div>
	);
};

export default OkrExampleCard;
